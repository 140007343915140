<template>
  <div>
    <div v-if="permissions['operacoes.index'].permissao">
      <div
        id="filtro"
      >
        <b-modal
          id="updateStatus"
          no-close-on-backdrop
          title=" Motivo Pendência"
          ok-title="Alterar"
          ok-only
          cancel-variant="danger"
          @ok="updateLot"
          @show="limparUpdateLot"
        >
          <b-row>
            <b-col
              cols="12"
              class="mt-2"
            >
              Motivo Pendência:
              <v-select
                v-model="novoStatus"
                label="title"
                :options="mtPend.options"
              />
            </b-col>
            <b-col
              cols="12"
              class="mt-2"
            >
              Descrição:
              <b-form-textarea
                v-model="description"
              />
            </b-col>
          </b-row>
        </b-modal>

        <b-row>
          <b-col md="12">
            <b-col>
              <b-row align-h="end">
                <b-row class="mb-3 ml-1">
                  <label>Grupo de Credores</label>
                </b-row>
                <b-form-checkbox
                  v-model="checkGrupoCredores"
                  class="mt-2"
                  switch
                />
              </b-row>
            </b-col>
            <b-row v-show="!checkGrupoCredores">
              <b-col>
                <label for="credorName">Credor</label>
                <v-select
                  id="credorName"
                  v-model="credor.selected"
                  multiple
                  label="title"
                  :options="credor.credOption"
                  :close-on-select="false"
                />
              </b-col>
            </b-row>
            <v-select
              v-show="checkGrupoCredores"
              v-model="grupoCredor.selected"
              :options="grupoCredor.credOption"
              multiple
              :close-on-select="false"
            />
          </b-col>
          <b-col
            md="6"
            class="mt-2"
          >
            <label>Período Vencimento</label>
            <b-row>
              <b-col cols="6">
                <b-form-input
                  id="vencimento-inicio"
                  v-model="vencimento.inicio"
                  locale="pt"
                  type="date"
                />
              </b-col>
              <b-col cols="6">
                <b-form-input
                  id="vencimento-final"
                  v-model="vencimento.final"
                  locale="pt"
                  type="date"
                />
              </b-col>
            </b-row>
          </b-col>

          <b-col
            v-show="mostrarBt"
            lg="6"
            class="mt-2"
          >
            <b-row align-h="between">
              <b-col>
                <label>Status</label>
              </b-col>
              <b-col class="d-flex justify-content-end">
                <b-form-checkbox
                  v-model="statusOp.exceto"
                  value="1"
                  unchecked-value="0"
                  inline
                  class="custom-control-primary"
                >
                  Exceto
                </b-form-checkbox>
              </b-col>
            </b-row>
            <v-select
              v-model="statusOp.selected"
              label="title"
              :options="statusOp.options"
            />
          </b-col>
          <b-col
            v-show="mostrarBt"
            lg="6"
            class="mt-2"
          >
            <b-row class="justify-content-between">
              <b-col>
                <label>Motivo Pendência</label>
              </b-col>
              <b-col class="d-flex justify-content-end">
                <b-form-checkbox
                  v-model="mtPend.exceto"
                  value="1"
                  inline
                  unchecked-value="0"
                  class="custom-control-primary"
                >
                  Exceto
                </b-form-checkbox>
              </b-col>
            </b-row>
            <v-select
              v-model="mtPend.selected"
              label="title"
              multiple
              :options="mtPend.options"
            />
          </b-col>
          <b-col
            lg="6"
            class="mt-2"
          >
            <b-row align-h="between">
              <b-col>
                <label>Classificação Negativação</label>
              </b-col>
              <b-col class="d-flex justify-content-end">
                <b-form-checkbox
                  v-model="classNeg.exceto"
                  value="1"
                  unchecked-value="0"
                  inline
                  class="custom-control-primary"
                >
                  Exceto
                </b-form-checkbox>
              </b-col>
            </b-row>
            <v-select
              v-model="classNeg.selected"
              label="title"
              :options="classNeg.options"
            />
          </b-col>
          <b-col
            lg="5"
            class="mt-2"
          >
            <b-row align-h="between">
              <b-col>
                <label>Classificação Cliente</label>
              </b-col>
              <b-col class="d-flex justify-content-end">
                <b-form-checkbox
                  v-model="classCliente.exceto"
                  value="1"
                  inline
                  unchecked-value="0"
                  class="custom-control-primary"
                >
                  Exceto
                </b-form-checkbox>
              </b-col>
            </b-row>
            <v-select
              v-model="classCliente.selected"
              label="title"
              multiple
              :options="classCliente.options"
            />
          </b-col>
          <b-col
            md="5"
            class="mt-2"
          >
            <b-row align-h="between">
              <b-col>
                <label>Período de contato</label>
              </b-col>
              <b-col>
                <b-row>
                  <b-form-checkbox
                    v-model="checkContato"
                    switch
                  />
                </b-row>
              </b-col>

            </b-row>
            <b-row v-show="!checkContato">
              <b-col md="6">
                <b-form-input
                  v-model="contato_inicio"
                  locale="pt"
                  type="date"
                />
              </b-col>
              <b-col md="6">
                <b-form-input
                  v-model="contato_final"
                  local="pt"
                  type="date"
                />
              </b-col>
            </b-row>
            <v-select
              v-show="checkContato"
              v-model="contatoDia"
              :options="numeroDias"
            />
          </b-col>
          <b-col
            md="2"
            class="mt-2"
          >
            <label>Região</label>
            <b-row>
              <b-col md="10">
                <v-select
                  v-model="uf"
                  multiple
                  :options="estados"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="3"
            class="mt-1"
          >
            <label>Situação</label>
            <b-row>
              <b-col md="12">
                <v-select
                  v-model="situacao.selected"
                  :options="situacao.options"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="3"
            class="mt-1"
          >
            <label>Status Localização</label>
            <b-row>
              <b-col md="12">
                <v-select
                  v-model="localizacao.selected"
                  :options="localizacao.options"
                  multiple
                  :close-on-select="false"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="6"
            class="mt-1"
          >
            <label>Período Cadastro Cliente</label>
            <b-row>
              <b-col cols="6">
                <b-form-input
                  id="periodo_cadastro-inicio"
                  v-model="periodo_cadastro.inicio"
                  locale="pt"
                  type="date"
                />
              </b-col>
              <b-col cols="6">
                <b-form-input
                  id="periodo_cadastro-final"
                  v-model="periodo_cadastro.final"
                  locale="pt"
                  type="date"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="6"
            class="mt-1"
          >
            <label>Período Cadastro Operacão</label>
            <b-row>
              <b-col cols="6">
                <b-form-input
                  id="periodo_cadastro_operacao-inicio"
                  v-model="periodo_cadastro_operacao.inicio"
                  locale="pt"
                  type="date"
                />
              </b-col>
              <b-col cols="6">
                <b-form-input
                  id="periodo_cadastro_operacao-final"
                  v-model="periodo_cadastro_operacao.final"
                  locale="pt"
                  type="date"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="4"
            class="mt-1 ml-2"
          >
            <label>Perfil do Cliente</label>
            <b-row>
              <b-col md="12">
                <v-select
                  v-model="perfilCliente.selected"
                  :options="perfilCliente.perfilOption"
                  multiple
                  :close-on-select="false"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="4"
            class="mt-1"
          >
            <label>Roteiro do Cliente</label>
            <b-row>
              <b-col md="12">
                <v-select
                  v-model="roteiroCliente.selected"
                  :options="roteiroCliente.roteiroOption"
                  multiple
                  :close-on-select="false"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="5"
            class="mt-1"
          >
            <label>Origem</label>
            <b-row>
              <b-col md="12">
                <v-select
                  v-model="origemTelefoneCliente.selected"
                  :options="origemTelefoneCliente.origemOption"
                  multiple
                  :close-on-select="false"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="5"
            class="mt-2"
          >
            <label>Valor R$</label>
            <b-row>
              <b-col md="6">
                <b-form-input
                  v-model="valor.inicial"
                  type="number"
                  size="sm"
                  placeholder="Inicial"
                />
              </b-col>
              <b-col md="6">
                <b-form-input
                  v-model="valor.final"
                  type="number"
                  size="sm"
                  placeholder="Final"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="3"
            class="mt-2"
          >
            <b-form-radio-group
              v-model="valor.select"
              name="radio-validation"
              :options="valor.options"
              value-field="value"
              text-field="text"
              disabled-field="notEnabled"
            />
          </b-col>
        </b-row>

      </div>

      <!-- <Filtro
        ref="filtro"
        :operacoes="true"
        title="Filtrar Operações"
        :set-data="setOperacoes"
      /> -->

      <!-- <OperacoesTable
        v-if="loadOperacoes"
        :operacoes="operacoes"
        :update-table="setFilter"
      /> -->
      <b-row>
        <b-col cols="12">
          <b-row
            class="mb-2"
            align-h="between"
          >
            <b-col cols="auto">
              <b-row align-h="start">
                <b-col cols="auto">
                  <b-button
                    v-show="mostrarBt"
                    id="btGerarFiltro"
                    variant="warning"
                    @click="setFilter"
                  >
                    Gerar Filtro
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <not-authorized />
    </div>
  </div>
</template>
<script>
import {
  BButton,
  BModal,
  VBModal,
  BRow,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormRadioGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@/../axios-auth'
import notAuthorized from '@/views/misc/notAuthorized.vue'
// import OperacoesTable from '@/views/backoffice/operacao/cards/OperacoesTable.vue'
// import Filtro from '@/components/filtros/OperacoesFiltro.vue'

export default {
  components: {
    // OperacoesTable,
    // Filtro,
    BButton,
    BModal,
    BRow,
    BCol,
    vSelect,
    BFormCheckbox,
    BFormInput,
    BFormRadioGroup,
    BFormTextarea,
    notAuthorized,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      mostrarBt: false,
      permissions: null,
      updateStatusOp: {
        status_operacao_id: null,
        ops_ids: null,
        motivo_id: 79,
        description: null,
      },
      load: true,
      selectAll: false,
      id: null,
      totalRows: 1,
      perPage: 10,
      currentPage: 1,
      filter: '',
      selected: [],
      novoStatus: null,
      description: null,
      fields: [
        'check',
        'status',
        { key: 'numero_operacao', label: 'operação', class: 'text-left' },
        { key: 'pendencia', label: 'pendência', class: 'text-left' },
        // 'id',
        { key: 'cliente', class: 'text-left' },
        { key: 'credor', class: 'text-left' },
        { key: 'data_vencimento', label: 'dt vencimento' },
        { key: 'data_processamento', label: 'dt processamento' },
        { key: 'valor_nominal', label: 'vl nominal', class: 'text-right' },
        { key: 'valor_atualizado', label: 'vl atualizado', class: 'text-right' },
        'desconto',
      ],
      operacoes: [],
      operacoes1: [],
      status_operacoes: {},
      clientesInfo: [],
      loadOperacoes: false,
      dir: null,
      credor: {
        credOption: [],
        selected: [],
        ids: [],
      },
      vencimento: {
        inicio: '',
        final: '',
      },
      statusOp: {
        selected: '',
        options: [],
        ids: [],
        exceto: '0',
      },
      mtPend: {
        selected: '',
        options: [],
        ids: [],
        exceto: '0',
      },
      classNeg: {
        selected: '',
        options: [],
        ids: [],
        exceto: '0',
      },
      classCliente: {
        options: [],
        ids: [],
        selected: '',
        exceto: '0',
      },
      valor: {
        options: [
          { text: 'Por Operação', value: 1 },
          { text: 'Ticket Médio Cliente', value: 2, notEnabled: true },
          { text: 'Ticket Médio Operação', value: 3, notEnabled: true },
        ],
        select: null,
        inicial: '',
        final: '',
        avaible: false,
      },
      contato_inicio: '',
      contato_final: '',
      data: [],
      filtro: null,
      estados: [
        { label: 'AC' },
        { label: 'AL' },
        { label: 'AP' },
        { label: 'AM' },
        { label: 'BA' },
        { label: 'CE' },
        { label: 'DF' },
        { label: 'ES' },
        { label: 'GO' },
        { label: 'MA' },
        { label: 'MT' },
        { label: 'MS' },
        { label: 'MG' },
        { label: 'PA' },
        { label: 'PB' },
        { label: 'PR' },
        { label: 'PE' },
        { label: 'PI' },
        { label: 'RJ' },
        { label: 'RN' },
        { label: 'RS' },
        { label: 'RO' },
        { label: 'RR' },
        { label: 'SC' },
        { label: 'SP' },
        { label: 'SE' },
        { label: 'TO' },
      ],
      uf: '',
      checkContato: false,
      numeroDias: [
        '1',
        '2',
        '3',
        '4',
        '5',
      ],
      contatoDia: '',
      checkGrupoCredores: false,
      grupoCredor: {
        credOption: [],
        selected: [],
        ids: [],
      },
      situacao: {
        selected: '',
        options: [
          'Inativo',
          'Ativo',
        ],
      },
      localizacao: {
        selected: '',
        options: [
          'Localizado ',
          'Nao Localizado',
          'Em Processo de Localizacao',
        ],
      },
      periodo_cadastro: {
        inicio: '',
        final: '',
      },
      periodo_cadastro_operacao: {
        inicio: '',
        final: '',
      },
      perfilCliente: {
        perfilOption: [],
        selected: [],
        ids: [],
      },
      roteiroCliente: {
        roteiroOption: [],
        selected: [],
        ids: [],
      },
      origemTelefoneCliente: {
        origemOption: [
          'Credor',
          'Atualização Cadastral',
          'Enriquecimento',
        ],
        selected: [],
      },
    }
  },
  // computed: {
  //   credorOptions() {
  //     return this.credor.credOption.sort((a, b) => {
  //       const nameA = a.title.toLowerCase()
  //       const nameB = b.title.toLowerCase()
  //       if (nameA < nameB) return -1
  //       if (nameA > nameB) return 1
  //       return 0
  //     })
  //   },
  // },

  watch: {
    selectAll(n) {
      n ? this.selecionarTodos() : this.limparSelecao()
    },
  },
  async created() {
    this.permissions = JSON.parse(localStorage.permissoes).operacoes
    this.addColor()
    axios.get('api/v1/operacoes', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      this.load = true
      this.operacoes = []
      // eslint-disable-next-line array-callback-return
      res.data.dados.operacoes.data.map(async dt => {
        await this.operacoes.push({
          id: dt.id,
          sigla: dt.status_operacao.sigla,
          numero_operacao: dt.numero_operacao,
          cliente: dt.cliente.nome,
          credor: dt.remessa.credor.nome,
          data_vencimento: this.dataHora(dt.data_vencimento, true),
          data_processamento: this.dataHora(dt.data_processamento, true),
          valor_nominal: this.valorBr(parseFloat(dt.valor_nominal), true),
          valor_atualizado: this.valorBr(parseFloat(dt.valor_atualizado), true),
          descricao: dt.descricao,
          desconto: dt.desconto,
          pendencia: dt.motivo_pendencia ? dt.motivo_pendencia.nome : dt.motivo_pendencia,
          status: dt.status_operacao.nome,
        })
        this.totalRows = this.operacoes.length
        this.clientesInfo.push({
          cliente_id: dt.cliente.id,
          // cliente_nome: dt.cliente.nome,
          // cliente_cpf_cnpj: dt.cliente.cpf_cnpj,
          // cliente_endereco: dt.cliente.endereco,
          // cliente_bairro: dt.cliente.bairro,
          // cliente_cep: dt.cliente.cep,
        })
      })
      this.load = false
      this.changeColor()
      this.limparSelecao()
    })
    // CREDORES
    axios.get('api/v1/credores/', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        const objeto = res.data.dados
        // eslint-disable-next-line array-callback-return
        objeto.map(dt => {
          if (dt.status) {
            this.credor.credOption.push({ title: dt.nome, id: dt.id })
          }
        })
        this.credor.credOption.sort((a, b) => {
          const nameA = a.title.toLowerCase()
          const nameB = b.title.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
      })

    // STATUS OPERAÇÃO
    axios.get('api/v1/info/statusoperacao', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => (
        this.statusOp.options.push({
          title: dt.nome,
          id: dt.id,
        })
      ))
    })

    // MOTIVO PENDENCIA
    await axios.get('api/v1/info/motivopendencia', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => (
        this.mtPend.options.push({
          title: dt.nome,
          id: dt.id,
        })
      ))
    })

    // CLASSIFICAÇÃO NEGATIVAÇÃO
    await axios.get('api/v1/info/classificacaonegativacao', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => (
        this.classNeg.options.push({
          title: dt.nome,
          id: dt.id,
        })
      ))
    })

    // CLASSIFICAÇÃO CLIENTE
    axios.get('api/v1/info/classificacaocliente', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => (
        this.classCliente.options.push({
          title: dt.nome,
          id: dt.id,
        })
      ))
    })

    // GRUPOS DE CREDORES
    axios.get('api/v1/grupo_credores/', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      const objeto = res.data.dados
      // eslint-disable-next-line array-callback-return
      objeto.map(dt => {
        this.grupoCredor.credOption.push({ label: dt.nome, grupo: dt.credores })
      })
    })
    // PERFIS DE CLIENTE
    axios.get('api/v1/perfil_cliente/listar/perfis', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        res.data.dados.map(perfil => {
          this.perfilCliente.perfilOption.push({
            label: perfil.nome,
            key: perfil.id,
          })
        })
      })
    // ROTEIRO
    axios.get('api/v1/roteiro/')
      .then(res => {
        res.data.dados.map(roteiro => {
          if (roteiro.status) {
            this.roteiroCliente.roteiroOption.push({
              label: roteiro.titulo,
              key: roteiro.id,
            })
          }
        })
      })
  },

  mounted() {
    // this.$bvModal.show('modal-filtro')
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async updateStatusOperacoes() {
      const body = {
        status_operacao_id: this.updateStatusOp.status_operacao_id.id,
        ops_ids: this.selected,
        motivoId: 12,
        description: this.updateStatusOp.description,
      }

      await axios.put('api/v1/operacoes/update/lote/status/operacao', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.setFilter()
          this.$swal({
            title: 'Alteração execultada!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(() => {
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },

    rowClick(item) {
      this.id = null
      this.id = item.id
      this.$bvModal.show('modal')
    },
    async addColor() {
      await axios.get('api/v1/info/statusoperacao', {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        this.status_operacoes = {}
        const colors = {
          C: '#B22222',
          L: '#66CDAA',
          D: '#696969',
          N: '#836FFF',
          A: '',
        }
        // eslint-disable-next-line array-callback-return
        res.data.dados.map(dt => {
          this.status_operacoes[dt.sigla] = {
            sigla: dt.sigla,
            color: colors[dt.sigla],
          }
        })
      })
      // this.setFilter()
    },
    limparModalStatus() {
      this.updateStatusOp.status_operacao_id = null
      this.updateStatusOp.description = null
    },

    limparUpdateLot() {
      this.novoStatus = null
      this.description = null
    },

    async updateLot() {
      const body = {
        motivo_pendencia_id: this.novoStatus.id,
        ops_ids: this.selected,
        description: this.description,
        motivoId: 27,
      }
      await axios.put('api/v1/operacoes/update/lote/motivo/pendencia/', body, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(() => {
        this.selected = []
        this.$swal({
          title: 'SUCESSO!',
          text: 'Alteração executada com sucesso',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            comfirmButton: 'btn btn-ptimary',
          },
          buttonStyling: false,
        })
      }).catch(() => {
        this.selected = []
        this.$swal({
          title: 'ERROR!',
          text: 'Erro ao executar alteração!',
          icon: 'error',
          customClass: {
            comfirmButton: 'btn btn-danger',
          },
        })
      })
      this.setFilter()
    },
    onRowSelected(item) {
      this.selected = item
    },
    limparSelecao() {
      this.selected = []
    },
    selecionarTodos() {
      this.selected = []

      this.operacoes1.map(dt => {
        this.selected.push(dt.id)
      })
    },

    async setOperacoes(data) {
      this.loadOperacoes = false
      await (this.operacoes = data)
      this.loadOperacoes = true
    },

    async setFilter() {
      this.load = true
      this.loadOperacoes = false
      let body = {}
      if (this.data_inicial && this.data_final) {
        body = {
          // pag: 1,
          // item_pag: 15,
          filtros: {
            periodo_contato: {
              data_inicial: '',
              data_final: '',
              exceto: 0,
            },
          },
        }
      } else {
        body = {
          // pag: 1,
          // item_pag: 15,
          filtros: {},
        }
      }
      // VALIDACAO CREDOR
      if (this.credor.selected.length) {
        this.credor.ids = []
        // eslint-disable-next-line array-callback-return
        await this.credor.selected.map(item => {
          this.credor.ids.push(item.id)
        })
        body.filtros.credores = this.credor.ids
      }
      if (this.checkGrupoCredores) {
        this.grupoCredor.ids = []
        const uniqueIds = [...new Set(this.grupoCredor.selected.flatMap(({ grupo }) => JSON.parse(grupo).map(({ id }) => id)))]
        body.filtros.credores = uniqueIds
      }

      // VALIDACAO VENCIMENTO
      if (this.vencimento.inicio && this.vencimento.final) {
        body.filtros.vencimento = {
          data_inicial: this.vencimento.inicio,
          data_final: this.vencimento.final,
        }
      }

      // VALIDACAO STATUS
      if (this.statusOp.selected) {
        body.filtros.status_operacao = {
          valor: this.statusOp.selected.id,
          // eslint-disable-next-line radix
          exceto: parseInt(this.statusOp.exceto),
        }
      }

      // VALIDACAO PENDENCIA
      if (this.mtPend.selected.length) {
        this.mtPend.ids = []
        // eslint-disable-next-line array-callback-return
        await this.mtPend.selected.map(item => {
          this.mtPend.ids.push(item.id)
        })
        body.filtros.motivo_pendencia = {
          valor: this.mtPend.ids,
          // eslint-disable-next-line radix
          exceto: parseInt(this.mtPend.exceto),
        }
      }

      // VALIDACAO VALOR
      // eslint-disable-next-line no-mixed-operators
      if (this.valor.select && this.valor.inicial !== 0 && this.valor.final !== 0 && this.valor.inicial !== null && this.valor.final !== null) {
        body.filtros.valor = {
          tipo: this.valor.select,
          valor_inicial: parseFloat(this.valor.inicial),
          valor_final: parseFloat(this.valor.final),
        }
      }

      // PERIODO CONTATO
      if (this.contato_inicio && this.contato_final) {
        body.filtros.periodo_contato = {
          data_inicio: `${this.contato_inicio} 00:00:00`,
          data_final: `${this.contato_final} 23:59:59`,
          exceto: 0,
        }
      }
      if (this.checkContato) {
        body.filtros.periodo_contato = {
          dias: this.contatoDia,
          exceto: 1,
        }
      }

      // VALIDCAO CLASSIFICACAO NEGATIVACAO
      if (this.classNeg.selected) {
        body.filtros.classificacao_negativacao = {
          valor: this.classNeg.selected.id,
          // eslint-disable-next-line radix
          exceto: parseInt(this.classNeg.exceto),
        }
      }

      // VALIDACAO CLASSIFICAÇÃO CLIENTE
      if (this.classCliente.selected.length) {
        this.classCliente.ids = []
        // eslint-disable-next-line array-callback-return
        await this.classCliente.selected.map(item => {
          this.classCliente.ids.push(item.id)
        })
        body.filtros.classificacao_cliente = {
          valor: this.classCliente.ids,
          // eslint-disable-next-line radix
          exceto: parseInt(this.classCliente.exceto),
        }
      }

      // VALIDACAO REGIAO
      if (this.uf.length) {
        const ufs = []
        this.uf.map(item => {
          ufs.push(item.label)
        })
        body.filtros.regiao = ufs
      }

      // VALIDACAO SITUACAO CLIENTE
      if (this.situacao.selected.length) {
        if (this.situacao.selected === 'Inativo') {
          body.filtros.situacao = {
            inativo: this.situacao.selected,
          }
        } else {
          body.filtros.situacao = {
            ativo: this.situacao.selected,
          }
        }
      }
      // VALIDACAO STATUS LOCALIZACAO
      if (this.localizacao.selected.length) {
        body.filtros.localizacao = {
          status: this.localizacao.selected,
        }
      }
      // VALIDACAO PERIODO CADASTRO CLIENTE
      if (this.periodo_cadastro.inicio.length && this.periodo_cadastro.final.length) {
        body.filtros.periodo_cadastro = {
          data_inicial: this.periodo_cadastro.inicio,
          data_final: this.periodo_cadastro.final,
        }
      }
      // VALIDACAO PERIODO CADASTRO OPERAÇÃO
      if (this.periodo_cadastro_operacao.inicio.length && this.periodo_cadastro_operacao.final.length) {
        body.filtros.periodo_cadastro_operacao = {
          data_inicial: this.periodo_cadastro_operacao.inicio,
          data_final: this.periodo_cadastro_operacao.final,
        }
      }
      // VALIDACAO PERFIL DO CLIENTE
      if (this.perfilCliente.selected.length) {
        this.perfilCliente.ids = []
        this.perfilCliente.selected.map(item => {
          this.perfilCliente.ids.push(item.key)
        })
        body.filtros.perfil_cliente = this.perfilCliente.ids
      }
      // VALIDACAO ROTEIRO
      if (this.roteiroCliente.selected.length) {
        this.roteiroCliente.ids = []
        this.roteiroCliente.selected.map(item => {
          this.roteiroCliente.ids.push(item.key)
        })
        body.filtros.roteiro_cliente = this.roteiroCliente.ids
      }
      // VALIDACAO ORIGEM
      if (this.origemTelefoneCliente.selected.length) {
        this.origemTelefoneCliente.ids = []
        this.origemTelefoneCliente.ids = this.origemTelefoneCliente.selected
        body.filtros.origem_telefone = this.origemTelefoneCliente.ids
      }
      if (Object.keys(body.filtros).length === 0) {
        this.$swal({
          title: 'Error!',
          text: 'Selecione pelo menos 1 filtro ',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        localStorage.setItem('filtrosCampanha', JSON.stringify(body))
        await axios.post('api/v1/operacoes/filtro', body, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        }).then(res => {
          // this.load = true
          this.operacoes = []
          // eslint-disable-next-line array-callback-return
          res.data.dados.operacoes.map(dt => {
            this.operacoes.push({
              id: dt.id,
              sigla: dt.status_operacao.sigla,
              numero_operacao: dt.operacao,
              cliente: dt.cliente,
              credor: dt.credor,
              data_vencimento: this.dataHora(dt.data_vencimento, true),
              data_processamento: this.dataHora(dt.data_processamento, true),
              valor_nominal: this.valorBr(parseFloat(dt.valor_nominal), true),
              valor_atualizado: this.valorBr(parseFloat(dt.valor_atualizado), true),
              descricao: dt.descricao,
              desconto: dt.desconto,
              pendencia: dt.motivo_pendencia ? dt.motivo_pendencia.nome : dt.motivo_pendencia,
              status: dt.status_operacao.nome,
            })
            this.totalRows = this.operacoes.length
            // this.clientesInfo.push({
            //   cliente_id: dt.cliente_id,
            //   cliente_nome: dt.cliente_nome,
            //   cliente_cpf_cnpj: dt.cliente_cpf_cnpj,
            //   cliente_endereco: dt.cliente_endereco,
            //   cliente_bairro: dt.cliente_bairro,
            //   cliente_cep: dt.cliente_cep,
            // })
          })
          this.load = false
          this.changeColor()
        })
      }
    },
    async changeColor() {
      this.operacoes1 = []
      await this.operacoes.map(dt => {
        const { sigla, color } = this.status_operacoes[dt.sigla]
        this.operacoes1.push({
          id: dt.id,
          sigla,
          color,
          pendencia: dt.pendencia,
          numero_operacao: dt.numero_operacao,
          cliente: dt.cliente,
          credor: dt.credor,
          data_vencimento: dt.data_vencimento,
          data_processamento: dt.data_processamento,
          valor_nominal: dt.valor_nominal,
          valor_atualizado: dt.valor_atualizado,
          descricao: dt.descricao,
          desconto: dt.desconto,
          status: dt.status,
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
