<template>
  <div>
    <b-row align-h="start">
      <b-col cols="auto">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modalAddGrupo
          class="mb-1"
          variant="primary"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Adicionar Grupo</span>
        </b-button>
      </b-col>
    </b-row>
    <b-coL>
      <div
        v-for="(cred, index) in grupoCredores"
        :key="index"
        class="d-flex justify-content-between align-items-center mt-1"
      >
        <div class="profile-user-info">
          <h6 class="mb-0.5">
            <strong>{{ cred.nome }}</strong>
          </h6>
          <h6 class="mb-0">
            <div
              v-for="(nome, i) in JSON.parse(cred.credores)"
              :key="i"
            >
              {{ nome.label }}
            </div>
          </h6>
        </div>
        <div>
          <feather-icon
            icon="EditIcon"
            size="18"
            class="ml-1 cursor-pointer color-icon"
            @click="editarGrupo(cred.id)"
          />
          <feather-icon
            icon="XIcon"
            size="18"
            class="ml-1 cursor-pointer color-icon"
            @click="removerGrupo(cred.id)"
          />
        </div>
      </div>
    </b-coL>
    <b-modal
      id="modalAddGrupo"
      title="Adicionar Grupo"
      ok-title="Cadastrar"
      cancel-title="Cancelar"
      cancel-variant="danger"
      @ok="cadastrarGrupo()"
    >
      <b-col>
        <b-row>
          <b-col md="12">
            <b-card>
              <b-row>

                <b-col>
                  <label for=""><strong>Nome do Grupo: </strong></label>
                  <b-form-input
                    v-model="form.nome"
                    type="text"
                  />
                </b-col>

              </b-row>
              <b-row class="mt-1">
                <b-col md="12">
                  <label for=""><strong>Credores </strong></label>

                  <v-select
                    v-model="form.credores"
                    :options="credores"
                    :close-on-select="false"
                    multiple
                  />

                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-modal>
    <b-modal
      ref="modalUpdate"
      title="Editar Grupo"
      ok-title="Atualizar"
      cancel-title="Cancelar"
      cancel-variant="danger"
      @ok="atualizarGrupo()"
    >
      <b-col>
        <b-row>
          <b-col md="12">
            <b-card>
              <b-row>

                <b-col>
                  <label for=""><strong>Nome do Grupo: </strong></label>
                  <b-form-input
                    v-model="formEdit.nome"
                    type="text"
                  />
                </b-col>

              </b-row>
              <b-row class="mt-1">
                <b-col md="12">
                  <label for=""><strong>Credores </strong></label>

                  <v-select
                    v-model="formEdit.credores"
                    :options="credores"
                    :close-on-select="false"
                    multiple
                  />

                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import axios from '../../../../../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BFormInput,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        nome: '',
        credores: [],
      },
      credores: [],
      grupoCredores: [],
      formEdit: {
        nome: '',
        credores: [],
      },
      grupoID: null,
    }
  },
  async created() {
    this.getCredores()
    this.getGrupos()
  },
  methods: {
    async getCredores() {
      await axios.get('api/v1/credores/listar/false', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(item => {
          this.credores.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
    clearForm() {
      this.form = {
        nome_grupo: '',
        grupo: [],
      }
      this.formEdit = {
        nome_grupo: '',
        grupo: [],
      }
      this.grupoCredores = []
    },
    cadastrarGrupo() {
      axios.post('api/v1/grupo_credores/store', this.form, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificação',
              icon: 'BellIcon',
              text: 'Grupo cadastrado com sucesso!',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
          this.clearForm()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificação',
              icon: 'BellIcon',
              text: 'Ops, cadastro não realizado',
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          })
          this.clearForm()
        })
    },
    getGrupos() {
      axios.get('api/v1/grupo_credores/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          res.data.dados.map(group => {
            this.grupoCredores.push(group)
          })
        })
        .catch()
    },
    editarGrupo(id) {
      this.grupoID = id
      axios.get(`api/v1/grupo_credores/edit/${id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          const dt = res.data.dados
          const credoresParse = JSON.parse(dt.credores)
          this.formEdit = {
            nome: dt.nome,
            credores: credoresParse,
          }
        })
        .catch()
      this.$refs.modalUpdate.show()
    },
    atualizarGrupo() {
      axios.put(`api/v1/grupo_credores/update/${this.grupoID}`, this.formEdit, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificação',
              icon: 'BellIcon',
              text: 'Grupo editado com sucesso!',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
          this.clearForm()
          this.getGrupos()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificação',
              icon: 'BellIcon',
              text: 'Ops, grupo não editado',
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          })
          this.clearForm()
          this.getGrupos()
        })
    },
    removerGrupo(id) {
      this.$swal({
        title: '',
        text: 'Deseja remover esse grupo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            axios.delete(`api/v1/grupo_credores/delete/${id}`, {
              headers: {
                Authorization: 'Bearer '.concat(localStorage.getItem('token')),
                accept: 'application/json',
              },
            })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Notificação',
                    icon: 'BellIcon',
                    text: 'Grupo deletado com sucesso!',
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                })
                this.clearForm()
                this.getGrupos()
              })
              .catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Notificação',
                    icon: 'BellIcon',
                    text: 'Ops, grupo não deletado',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                })
                this.clearForm()
                this.getGrupos()
              })
          }
        })
    },
  },
}
</script>
