<template>
  <div>
    <b-table
      ref="selectableTable"
      selectable
      select-mode="single"
      :items="items"
      :fields="fields"
      responsive="sm"
      @row-selected="onRowSelected"
    >
      <!-- Example scoped slot for select state illustrative purposes -->
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <i class="feather icon-disc primary" />
        </template>
        <template v-else>
          <i class="feather icon-circle" />
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },
  data() {
    return {
      // selectedCheck: false,
      modes: ['multi', 'single', 'range'],
      fields: ['selected', 'id', 'titulo'],
      items: [{
        id: 11, titulo: 'DISCADOR SEM RECEPTIVO II',
      },
      {
        id: 17, titulo: 'CARTEIRAS DIVERSAS',
      },
      {
        id: 18, titulo: 'FACULDADES',
      },
      {
        id: 19, titulo: 'FACULDADESo',
      },
      {
        id: 20, titulo: 'DISCADOR SEM RECEPTIVO I',
      },
      {
        id: 22, titulo: 'DEMAIS CARTEIRAS',
      },
      {
        id: 24, titulo: 'NOTIFICAÇÃO DE DEBITO',
      },
      {
        id: 25, titulo: 'GERENCIAMENTO GERAL',
      },
      {
        id: 43, titulo: 'GERENCIAMENTO DE PARCELAS',
      },
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      selectMode: 'multi',
      selected: [],
    }
  },
  methods: {
    onRowSelected(items) {
      localStorage.setItem('templateDiscador', items[0].id)
      if (localStorage.getItem('templateSms')) {
        localStorage.removeItem('templateSms')
      } else if (localStorage.getItem('templateEmail')) {
        localStorage.removeItem('templateEmail')
      } else if (localStorage.getItem('templateWhatsapp')) {
        localStorage.removeItem('templateWhatsapp')
      } else if (localStorage.getItem('templateManual')) {
        localStorage.removeItem('templateManual')
      }
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
