<template>
  <div>
    <label><strong>Selecione os executivos</strong></label>
    <v-select
      v-model="executivoCobranca"
      label="nome"
      multiple
      :options="items"
      :close-on-select="false"
      @input="getListOfExecutive"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from '../../../../../axios-auth'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      items: [],
      executivoCobranca: [],
      listOfExecutivesID: [],
    }
  },
  created() {
    axios.get('/api/v1/executivo', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.sort((a, b) => {
        if (a.nome < b.nome) return -1
        if (a.nome > b.nome) return 1
        return 0
      })
      res.data.map(item => {
        this.items.push(item)
      })
    })
  },
  methods: {
    getListOfExecutive() {
      this.listOfExecutivesID = []
      this.executivoCobranca.map(item => {
        this.listOfExecutivesID.push(item.id)
      })
      localStorage.setItem('templateManual', JSON.stringify(this.listOfExecutivesID))
      if (localStorage.getItem('templateDiscador')) {
        localStorage.removeItem('templateDiscador')
      } else if (localStorage.getItem('templateEmail')) {
        localStorage.removeItem('templateEmail')
      } else if (localStorage.getItem('templateSms')) {
        localStorage.removeItem('templateSms')
      } else if (localStorage.getItem('templateWhatsapp')) {
        localStorage.removeItem('templateWhatsapp')
      }
    },
  },
}
</script>
