<template>
  <div>
    <div v-if="permissions['campanhas.index'].permissao">
      <b-card-actions
        ref="refresh"
      >
        <b-row align-h="end">
          <b-col cols="auto">
            <b-button
              v-if="permissions['campanhas.store'].permissao"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.nova-campanhas1
              class="mb-1"
              variant="success"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Campanha</span>
            </b-button>
            <b-button
              v-if="permissions['campanhas.store'].permissao"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modalGrupoCredores
              class="mb-1 ml-1"
              variant="primary"
            >
              <feather-icon
                icon="UsersIcon"
                class="mr-50"
              />
              <span class="align-middle">Grupo de Credores</span>
            </b-button>
          </b-col>
        </b-row>
        <!-- <Filtro
          ref="filtro"
        /> -->

        <List
          ref="table"
        />
        <b-modal
          id="nova-campanhas1"
          ref="nova-campanha1"
          hide-footer
          size="lg"
          title="Gerar Campanha"
        >
          <b-card-actions
            ref="modal"
          >
            <b-button
              v-show="closebt"
              id="fechar-modal"
              @click="hideModal"
            >
              X
            </b-button>
            <New2
              :hide="hideModal"
            />
          </b-card-actions>
        </b-modal>
        <b-modal
          id="modalGrupoCredores"
          title="Grupo de Credores"
        >
          <GrupoCredores />
        </b-modal>
      </b-card-actions>
    </div>
    <div v-else>
      <not-authorized />
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import BCardActions from '@core/components/b-card-loading/BCardActions.vue'
// import Filtro from './cards/filtro.vue'
import notAuthorized from '@/views/misc/notAuthorized.vue'
import List from './cards/list.vue'
import New2 from './cards/FormWizard.vue'
import GrupoCredores from './cards/GrupoCredores.vue'

export default {
  components: {
    BButton,
    // Filtro,
    BRow,
    BCol,
    List,
    New2,
    GrupoCredores,
    BCardActions,
    notAuthorized,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      closebt: false,
      permissions: null,
    }
  },
  async created() {
    this.permissions = JSON.parse(localStorage.permissoes).campanhas
  },
  methods: {
    async updateTable() {
      this.$refs.table.updateList()
    },
    hideModal() {
      this.updateTable()
      this.$bvModal.hide
      this.$nextTick(() => {
        this.$refs['nova-campanha1'].toggle('#toggle-btn')
      })
    },
    loading(state = true) {
      this.$refs.refresh.showLoading = state
    },
  },
}
</script>
